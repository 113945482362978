@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.ant-menu-horizontal {
  background-color: #efefef;
}

li.ant-menu-item {
  float: right;
  text-align: right;
}

li#brand-name {
  float: left;
  text-align: left;
}

#about {
  background-color: rgb(184, 220, 254);

}

.about-div {
    text-align: left;
    margin: 0 20%;
}

.about-txt { 
    font-size: 150%;
    padding-top: 11%;
    padding-bottom: 11%;
}

.about-title {
  font-weight: 400;
}

.about-p {
  font-weight: normal;
  font-size: 20px;
}

#tech {
  background-color: #efefef;
/*  border-top: 30px;*/
  text-align: left;
  width: 100%;
}

.tech-div {
  margin: 0 20%;
  padding-top: 2.75%;
  padding-bottom: 2.75%;
  text-align: left;
  font-weight: 400;
}

.skills {
  font-size: 1.2em;
  font-weight: normal;
  margin: 0.5em;
}

.contact-links {
  font-size: 1.75em;
}

.contact-link-name {
  font-size: 1.25em;
  display: inline;
}

.cl {
  display: block;
}

.ant-modal-confirm-title {
  font-weight: bolder !important;
}

/*.ant-btn-primary {
  display: none;
}*/

.anticon-info-circle {
  display: none;
}

#resume {
  width: 100%;
  height: 100%;
}

.resume-item {
  width: 100%;
  height: 60em;
}

.individual-project {
  padding-top: 3%;
  padding-bottom: 3%;
}

.blue-project {
  background-color: rgb(184, 220, 254);
}

.grey-project {
  background-color: #efefef;
}

.card-content {
  text-align: left;
  width: 45%;
  right: 10%;
  height: 45%;
  float: right;

}

.card-paragraphs {
  max-height: 30px;
  font-weight: 500;
}

iframe {
  width: 50%;
  height: 20em;
  left: 10%;
  left: 5%;
}

#footer {
  bottom: 0px;
}

.footer-items {
  background-color: rgb(184, 220, 254);
  padding-top: 2.5%;
  float: left;
  width: 100%;
  font-size: 150%;
  text-align: left;
  padding-bottom: 2.5%;
}

.footer-links {
    margin-left: 2em;
    font-size: 1em;
}
